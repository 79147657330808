import Navbar from "./Componants/Navbar/Navbar";
import Footer from "./Componants/Footer/Footer";
import "./App.css";
import { Route ,Routes} from "react-router-dom";
import Home from "./Home/Home";
import About from "./About/About";
import Workers from "./Workers/Workers";
import Contact_us from "./Contact_us/Contact_us";
import Blog from "./Blog/Blog";
import Work_Categories from "./Work_Categories/Work_Categories";
import Single_blog from "./Single_blog/Single_blog";
import Jobs from "./Componants/Jobs/Jobs";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import TopToScroll from "./TopToScroll";
import Login from "./Auth/Login";


function App() {
  return (
    <>
      <Navbar />
      <TopToScroll/> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About/>} />
        <Route path="/workers" element={<Workers />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/contact" element={<Contact_us />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/single_blog/:id" element={<Single_blog />} />
        <Route path="/workCategories" element={<Work_Categories />} />
        <Route path="/terms_conditions" element={<TermsAndConditions />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
