import React, { useState } from "react";
import "./Worker.css";
import WorkersApi from "./WorkerApi";
import "react-loading-skeleton/dist/skeleton.css";
import Login from "../Auth/Login";
import { Link } from "react-router-dom";

const Workers = () => {
  const [searchResult, setSearchResult] = useState("");

  const handleSearch = (e) => {
    setSearchResult(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-12 bg-light text-center">
            <form onSubmit={handleSubmit}>
              {" "}
              {/* Attach onSubmit to the form */}
              <div className="row justify-content-center mt-5 pt-5 pb-3">
                <h3>
                  <span className="color">F</span>ind{" "}
                  <span className="color">W</span>orkers
                </h3>

                <div className="col-lg-6 col-11 py-2">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control border-0"
                      placeholder="Search"
                      value={searchResult}
                      onChange={handleSearch}
                    />
                    <div className="input-group-append">
                      <button
                        className="input-group-text form-control border-0 bg-white"
                        type="submit"
                        id="basic-addon2"
                      >
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-10">
            {/* <WorkersApi searchResult={searchResult} containerDesign={"col-sm-12 col-md-12 mb-4"} /> */}
            <Link to="/login">
              {" "}
              <h5 className="text-center text-primary" style={{textDecoration:"underline"}}>Login to find workers</h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workers;
