export const base_url = 'https://backend-omega-lake.vercel.app/';
export const getAllWorker = `${base_url + 'api/worker/all'}`;
export const login = `${base_url + '/api/user/login'}`;
export const verify = `${base_url + 'api/user/verify'}`;
export const getAllCategory = `${base_url + 'api/worker/category'}`;
export const getAllCategories = `${base_url + 'api/worker/category'}`;
export const getGetAllOffers = `${base_url + 'api/offer/all'}`;
export const getAllBlogs = `${base_url + 'api/blog/verified'}`;
export const getSingleBlog = `${base_url + 'api/blog'}`;
export const getAllTermsAndConditions = `${base_url + 'api/termsCondition/all'}`;
export const getAllJobs = `${base_url + 'api/workpost/website/all/?searchQuary'}`;
 