import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { login, verify } from "../Componants/api/api_base";

export default function Login() {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState(""); // Store error messages
  const [success, setSuccess] = useState(""); // Store success messages
  const [cooldown, setCooldown] = useState(0); // Cooldown timer for OTP requests
  const navigate = useNavigate();

  // Function to send OTP
  const sendOtp = async () => {
    if (!phone.trim()) {
      setError("Phone number is required.");
      return;
    }
    if (cooldown > 0) {
      setError(`Please wait ${cooldown} seconds before requesting OTP again.`);
      return;
    }

    try {
      const res = await axios.post(login, { phone });
      setSuccess(res.data.message);
      setError("");
      setOtpSent(true);
      setCooldown(30); // Set cooldown to 30 seconds

      // Start countdown timer
      const countdown = setInterval(() => {
        setCooldown((prev) => {
          if (prev === 1) {
            clearInterval(countdown);
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Error sending OTP";

      if (errorMessage.includes("Too many OTP requests")) {
        setError("Too many requests! Please try again after some time.");
        setCooldown(60); // Increase cooldown if rate limit is hit
      } else {
        setError(errorMessage);
      }
    }
  };

  // Function to verify OTP
  const verifyOtp = async () => {
    if (!otp.trim()) {
      setError("OTP is required.");
      return;
    }
    try {
      const res = await axios.post(verify, { phone, otp });
      if (res.data.message === "OTP Verified!") {
        setSuccess("OTP Verified! Redirecting...");
        setError("");
        setTimeout(() => navigate("/dashboard"), 2000);
      } else {
        setError(res.data.message);
      }
    } catch (error) {
      setError(error.response?.data?.message || "Error verifying OTP");
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <div className="card shadow p-4" style={{ width: "350px" }}>
        <h3 className="text-center mb-3">Login with OTP</h3>
        <p className="text-center text-muted mb-4">Login to Find Worker</p>

        {/* Display Error Message */}
        {error && <div className="alert alert-danger">{error}</div>}
        {/* Display Success Message */}
        {success && <div className="alert alert-success">{success}</div>}

        {!otpSent ? (
          <>
            <div className="mb-3">
              <label className="form-label">Phone Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <button
              className="btn btn-primary w-100"
              onClick={sendOtp}
              disabled={cooldown > 0} // Disable if cooldown is active
            >
              {cooldown > 0 ? `Wait ${cooldown}s` : "Send OTP"}
            </button>
          </>
        ) : (
          <>
            <div className="mb-3">
              <label className="form-label">Enter OTP</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <button className="btn btn-success w-100" onClick={verifyOtp}>
              Verify OTP
            </button>
          </>
        )}
      </div>
    </div>
  );
}
